import { SpcInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { SpcApiInspectionsGetRequest, SpcApiInspectionsInspectionIdGetRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const inspections = createQueryKeys('inspections', {
    all: null,
    get: (params: SpcApiInspectionsGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // 검사 기준 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => SpcInstance.inspectionsGet(queryParams),
        }
    },
    // get: (params : SpcApiInspectionsGetRequest) => {
    //     const query = { ...params }; // params 객체를 복사합니다.

    //     if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    //     if (params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     return {
    //         queryKey: [query, JSON.stringify(query.query)],
    //         queryFn: () => SpcInstance.inspectionsGet(query),
    //     }
    // },
    detail: (params: SpcApiInspectionsInspectionIdGetRequest) => ({
        queryKey: [params.inspectionId],
        queryFn: () => SpcInstance.inspectionsInspectionIdGet(params)
    }),
    find: (params) => ({
        queryKey: [params],
        queryFn: () => SpcInstance.inspectionsFindPost(params)
    }),
})