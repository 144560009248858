import { SpcInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { SpcApiSpecificationsFindPostRequest, SpcApiSpecificationsGetRequest, SpcApiSpecificationsPostRequest, SpcApiSpecificationsSpecificationIdDeleteRequest, SpcApiSpecificationsSpecificationIdGetRequest, SpcApiSpecificationsSpecificationIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const specifications = createQueryKeys('specifications', {
    all: null,
    get: (params: SpcApiSpecificationsGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // 검사 기준 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => SpcInstance.specificationsGet(queryParams),
        }
    },
    // get: (params: SpcApiSpecificationsGetRequest) => {
    //     const query = { ...params }; // params 객체를 복사합니다.

    //     if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    //     if (params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     return {
    //         queryKey: [query, JSON.stringify(query.query)],
    //         queryFn: () => SpcInstance.specificationsGet(query),
    //     }
    // },
    detail: (params: SpcApiSpecificationsSpecificationIdGetRequest) => ({
        queryKey: [params.specificationId],
        queryFn: () => SpcInstance.specificationsSpecificationIdGet(params)
    }),
    find: (params: SpcApiSpecificationsFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => SpcInstance.specificationsFindPost(query),
        }
    },
})

export const mutateSpecifications = createMutationKeys('specifications', {
    create: (params: SpcApiSpecificationsPostRequest) => ({
        mutationKey: [params.specificationsGetRequest],
        mutationFn: () => SpcInstance.specificationsPost(params),
    }),
    update: (params: SpcApiSpecificationsSpecificationIdPutRequest) => ({
        mutationKey: [params.specificationId, params.specificationsGetRequest],
        mutationFn: () => SpcInstance.specificationsSpecificationIdPut(params)
    }),
    delete: (params: SpcApiSpecificationsSpecificationIdDeleteRequest) => ({
        mutationKey: [params.specificationId],
        mutationFn: () => SpcInstance.specificationsSpecificationIdDelete(params)
    }),
})

export const specificationsQuery = mergeQueryKeys(specifications, mutateSpecifications);