import { Flex, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useMemo, useState } from "react";

import { FKInfoLabel, FKMultiSelect } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { IconX } from "@tabler/icons-react";
import { useItemsMultiAutoComplete, useItemsMultiCode } from "./multiAuto-useGetItemsQuery";

interface ItemMultiProps extends React.ComponentPropsWithoutRef<"div"> {
    label: string;
    value: string;
}

export interface ItemsMultiAutoCompleteProps
    extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
    value: string[];
    onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
    maxDropdownHeight?: number;
    width?: string;
}

export const ItemsMultiAutoComplete = (
    params: ItemsMultiAutoCompleteProps
) => {
    const {
        value: items,
        onChange,
        maxDropdownHeight,
        width,
        ...etcParams
    } = params;
    const [focused, setFocused] = useState<boolean>(true);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const { data: options } = useItemsMultiAutoComplete(
        focused,
        searchKeyword
    );
    const { data: initialOptions } = useItemsMultiCode(
        !!searchKeyword,
        searchKeyword ?? null
    );

    const onChangeHandler = (e: string[]) => {
        onChange && onChange(e);
    };

    const SelectItem = forwardRef<HTMLDivElement, ItemMultiProps>(
        ({ label: name, value: code, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Flex direction="row" justify="space-between" align="center">
                    <Flex align={"center"}>
                        <Text>{name}</Text>
                        <Text fz="xs">(품목분류명: {code})</Text>
                    </Flex>
                </Flex>
            </div>
        )
    );

    // data prop에 들어가는 중복 제거된 data를 useMemo로 감싸기
    const filteredOptions = useMemo(() => {
        const combinedOptions = [
            ...(options ?? []),
            ...(initialOptions ?? []),
            ...(items.map((value) => ({ label: value, value })) ?? []),
        ];

        return combinedOptions.reduce(
            (unique: ItemMultiProps[], option: ItemMultiProps) =>
                unique.some((u) => u.value === option.value) ? unique : [...unique, option],
            []
        );
    }, [options, initialOptions, items]);

    return (
        <FKMultiSelect
            clearable
            value={items}
            data={filteredOptions}
            onChange={onChangeHandler}
            searchable
            itemComponent={SelectItem}
            searchValue={searchKeyword}
            withinPortal
            onDropdownOpen={() => setFocused(true)}
            onDropdownClose={() => setFocused(false)}
            maxDropdownHeight={maxDropdownHeight ?? 150}
            onSearchChange={setSearchKeyword}
            nothingFound="No Options"
            rightSection={ItemInfo({
                Item: items,
                onChange: onChangeHandler,
            })}
            {...etcParams}
        />
    );
};

const ItemInfo = (params: {
    Item?: string[];
    onChange: (e: string[]) => void
}) => {
    const { Item, onChange } = params;

    const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange([]);
    };

    return Item?.length ? (
        <FKInfoLabel>
            <IconX size="1rem" onClick={clearHandler} />
        </FKInfoLabel>
    ) : null;
};

