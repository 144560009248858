import { purchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { DeliveryTable } from "@/features/order/table/deliveryTable";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Select, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner, PurchaseOrdersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { usePruchaseOrderOutgingLogic } from "../hook/usePurchaseOrderOutging";

interface PurchaseOrderItemsSelectFormProps {
    formatterProps: PurchaseOrdersGet200ResponseRowsInner
}

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const PurchaseOrderItemsOutgingForm = (params: PurchaseOrderItemsSelectFormProps) => {
    const { formatterProps } = params;
    const { closeModal } = useModal();
    const [selectedRadio, setSelectedRadio] = useState(null);
    const { data: purchaseItemData } = useQuery({
        ...purchaseOrderItems.detail({
            purchaseOrderItemId: selectedRadio ?? 0,
        }),
        enabled: !!selectedRadio,
    })
    const { onSubmit, form, inventoryData, setSelectLot, selectLot } = usePruchaseOrderOutgingLogic(purchaseItemData?.data)

    const [selectedLotId, setSelectedLotId] = useState<string | null>(null); // Select의 선택된 값 상태

    const handleRadioChange = (id: number | any) => {
        setSelectedRadio(id);
    };

    useEffect(() => {
        // selectedRadio가 변경될 때 Select 값 초기화
        setSelectedLotId(null);
        setSelectLot(undefined);
    }, [selectedRadio]);

    useEffect(() => {
        if (selectLot?.locationCode) {
            form.setFieldValue('locationCode', selectLot.locationCode);
        }
    }, [selectLot]);

    return (
        <>
            <Wrapper>
                {formatterProps.purchaseOrderItems && formatterProps.purchaseOrderItems.length > 0 && (
                    formatterProps.purchaseOrderItems.map((items) => (
                        <DeliveryTable
                            id={items.id}
                            partnerCode={items.partnerCode}
                            itemCode={items.itemCode}
                            quantity={items.quantity}
                            locationCode={items.sourceLocationCode}
                            selectedRadio={selectedRadio}
                            onChange={handleRadioChange}
                        />
                    ))
                )}
                {formatterProps.purchaseOrderItems && formatterProps.purchaseOrderItems.length === 0 ? (
                    <Flex>
                        <Text size="xl" fw="bold">
                            수주항목이 없습니다. 수주항목을 생성해주세요.
                        </Text>
                    </Flex>
                ) : (
                    <>
                        <Create.Input
                            disabled
                            label="품목코드"
                            withAsterisk
                            {...form.getInputProps("itemCode")}
                            defaultValue={purchaseItemData?.data?.itemCode}
                        />
                        <Select
                            label="LOT 정보"
                            clearable
                            value={selectedLotId} // Select의 value를 상태로 연결
                            data={inventoryData?.data ? inventoryData.data
                                .filter((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item?.quantity && item?.quantity > "0")
                                .map((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => {
                                    const name = item?.lot?.name ?? '-'
                                    const expiration = item?.lot?.expiration === null ? '-' : timeUtil(item?.lot?.expiration ?? '')
                                    const locationCode = item?.locationCode
                                    const quantity = item?.quantity
                                    return {
                                        value: item.lotId ? item.lotId.toString() : '', // value를 string으로 변환
                                        label: `이름 : ${name} 유효기한 : ${(expiration)} 로케이션코드 : ${locationCode} 수량 : ${quantity}`
                                    }
                                }) : []}
                            onChange={(e) => {
                                setSelectedLotId(e); // 선택된 LOT의 ID를 상태로 업데이트
                                const matchedItem = inventoryData?.data?.find((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item.lotId === Number(e));
                                setSelectLot(matchedItem);
                            }}
                        />
                        <LocationAutoComplete
                            label="로케이션코드"
                            withAsterisk
                            {...form.getInputProps("locationCode")}
                            defaultValue={selectLot !== undefined ? selectLot?.locationCode : ''}
                        />
                        <Create.Input
                            label="수량"
                            withAsterisk
                            type="number"
                            {...form.getInputProps("quantity")}
                            defaultValue={selectLot !== undefined ? selectLot?.quantity : ''}
                        />
                        <Create.Input
                            disabled
                            label="만료일"
                            {...form.getInputProps("lotInfo.expiration")}
                            defaultValue={selectLot !== undefined ? selectLot?.lot?.expiration : ''}
                        />
                        <Create.Input
                            label="수주 정보"
                            {...form.getInputProps("purchaseOrderItemId")}
                            defaultValue={formatterProps?.partnerCode}
                        />
                        {
                            customFunctions.ADD_PURCHASE_ORDER_INVOICE_DATE && (
                                <DateInput
                                    label="출하 날짜"
                                    valueFormat="YYYY-MM-DD"
                                    value={dayjs(form.values.invoiceDate).toDate()}
                                    onChange={(e) => {
                                        form.setFieldValue('invoiceDate', dayjs(e).startOf('day')?.toISOString());
                                    }}
                                />
                            )
                        }
                        <Create.ButtonBox>
                            <Create.Button color="gray" onClick={closeModal}>
                                취소
                            </Create.Button>
                            <Create.Button onClick={onSubmit} disabled={!selectedRadio}>납품</Create.Button>
                        </Create.ButtonBox>
                    </>
                )}
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;