import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PurchaseOrderItemsAutoComplete } from "@/features/ui/autoComplete/purchaseOrderItems/purchaseOrderItems-autoComplete";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiWmsOutgoingPostRequest, WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

export const OutgoingForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();

  const { mutate: outgoingMutate } = useMutation(
    (params: DefaultApiWmsOutgoingPostRequest) =>
      mutateInventories.outgoing(params).mutationFn(params as DefaultApiWmsOutgoingPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
      }
    }
  )
  const form = useForm<Partial<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner>>({
    initialValues: {
      itemCode: undefined,
      locationCode: undefined,
      quantity: undefined,
      lotId: null,
      purchaseOrderItemId: undefined,
      lotInfo: {
        name: null,
        expiration: null,
      },
    },
  });

  const onSubmit = () => {
    try {
      outgoingMutate(
        {
          workLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner: {
            itemCode:
              formatterProps.itemCode,
            purchaseOrderItemId: form.values.purchaseOrderItemId,
            locationCode:
              formatterProps.locationCode,
            quantity: form.values.quantity ? form.values.quantity : formatterProps.quantity as string,
            lotId: Number(formatterProps.lotId || form.values.lotId),
            lotInfo: {
              name:
                formatterProps.lot.name ||
                (form.values.lotInfo && form.values.lotInfo.name
                  ? form.values.lotInfo.name
                  : null),
              expiration:
                formatterProps.lot.expiration ||
                (form.values.lotInfo && form.values.lotInfo.expiration
                  ? form.values.lotInfo.expiration.toString()
                  : null),
            },
            actionFrom: 'WEB'
          },
        },
        {
          onSuccess: (res: { status: number }) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "재고 출고에 성공하였습니다.",
                "재고 출고 성공",
                "green"
              )
            } else {
              customAlert(
                "재고 출고에 실패하였습니다.",
                "재고 출고 실패",
                "red"
              )
            }
          },
        }
      );
    } catch (e) {
      customAlert(
        "재고 출고에 실패하였습니다.",
        "재고 출고 실패",
        "red"
      )
    }
  };

  return (
    <>
      <Create.Wrapper>
        <Create.ItemAutoComplete
          disabled
          label="품목코드"
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps?.itemCode}
        />
        <LocationAutoComplete
          disabled
          label="로케이션코드"
          withAsterisk
          {...form.getInputProps("locationCode")}
          defaultValue={formatterProps?.locationCode}
        />
        <PurchaseOrderItemsAutoComplete
          label="납품 할 수주 선택"
          placeholder="수주 선택"
          w={'100%'}
          itemCodes={formatterProps?.itemCode}
          {...form.getInputProps("purchaseOrderItemId")}
        />
        <Create.Input
          label="수량"
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />
        <Create.Input
          disabled
          label="LOT ID"
          {...form.getInputProps("lotId")}
          defaultValue={formatterProps?.lotId}
        />
        <Create.Input
          disabled
          label="로트명"
          {...form.getInputProps("lotInfo.name")}
          defaultValue={formatterProps?.lot?.name}
        />
        <Create.Date
          disabled
          label="만료일"
          //   placeholder="만료일을 선택하세요."
          minDate={new Date()}
          valueFormat="YYYY-MM-DD HH:mm:ss"
          {...form.getInputProps("lotInfo.expiration")}
          defaultValue={formatterProps?.lot?.expiration}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button onClick={onSubmit}>재고 출고</Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
